<template>
  <div id="home">
    <div class="relative">
      <button class="btn btn-light atualizar" :disabled="loading" @click="triggerFilter">
        <font-awesome-icon :icon="['fas', 'sync-alt']"  :class="loading && 'fa-spin'" style="margin:0!important"/>
      </button>
    </div>
    <div class="top-filter">

      <div class="filter-title" >
        <font-awesome-icon :icon="['fas', 'search']" size="2x" v-if="!loading" @click="triggerFilter"/>
        <font-awesome-icon :icon="['fas', 'sync-alt']" size="2x" v-if="loading" spin/>
        
        {{total}} veículo(s)<br/> encontrado(s)
      </div>
      <div class="d-grid gap-2 w-100 toggle-filter">
        <button class="btn btn-sm btn-light" @click="toggleFilter"><font-awesome-icon :icon="['fas', 'filter']"   /> {{labelFiltro}}</button>
      </div>
      <div class="filters" v-if="filterOpen">
        <div class="dropdown d-grid gap-2"> 
          <button class="btn btn-sm btn-light dropdown-toggle" 
                  type="button" 
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  >
             CIDADES{{filters.fields.CodigoCidade.length > 0 ? `(${filters.fields.CodigoCidade.length})` : ''}}
          </button>
          
          <ul class="dropdown-menu"  >
            <li class="text-right pr-1">
              <button type="button" @click="close" class="btn-close btn-sm" aria-label="Close" data-v-fae5bece=""></button>
            </li>
            <li data-v-61dd7a3d=""><hr class="dropdown-divider" data-v-61dd7a3d=""></li>
            <li class="dropdown-item-check" v-for="f in filters.source.cidades" :key="f.codigoCidade">
              <div class="form-check ">
                <input class="form-check-input" 
                        @change="triggerFilter" v-model="filters.fields.CodigoCidade" type="checkbox" :value="f.codigoCidade" :id="'cidade-'+f.codigoCidade">
                <label class="form-check-label" :for="'cidade-'+f.codigoCidade">
                  {{f.nomeCidade}}/{{f.siglaEstado}}
                </label>
              </div>
            </li>
            
          </ul>
        </div>
        <div class="dropdown d-grid gap-2"> 
          <button class="btn btn-sm btn-light dropdown-toggle" 
                  type="button" 
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  >
             MARCAS{{filters.fields.CodigoMarca.length > 0 ? `(${filters.fields.CodigoMarca.length})` : ''}}
          </button>
          <ul class="dropdown-menu">
            <li class="text-right pr-1">
              <button type="button" @click="close" class="btn-close btn-sm" aria-label="Close" data-v-fae5bece=""></button>
            </li>
            <li data-v-61dd7a3d=""><hr class="dropdown-divider" data-v-61dd7a3d=""></li>
            <li class="dropdown-item-check" v-for="f in filters.source.marcas" :key="f.codigoMarca">
              <div class="form-check ">
                <input class="form-check-input" 
                       v-model="filters.fields.CodigoMarca" 
                       type="checkbox" 
                       :value="f.codigoMarca" 
                       :id="'marca-'+f.codigoMarca"
                       @change="triggerFilter">
                <label class="form-check-label" :for="'marca-'+f.codigoMarca">
                  {{f.nomeMarca}}
                </label>
              </div>
            </li>
            
          </ul>
        </div>
        <div class="dropdown d-grid gap-2"> 
          <button class="btn btn-sm btn-light dropdown-toggle" 
                  type="button" 
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  >
             VALOR{{valorCount}}
          </button>
          <ul class="dropdown-menu">
            <li class="text-right pr-1">
              <button type="button" @click="close" class="btn-close btn-sm" aria-label="Close" data-v-fae5bece=""></button>
            </li>
            <li data-v-61dd7a3d=""><hr class="dropdown-divider" data-v-61dd7a3d=""></li>
            <li class="dropdown-item-check mb-2 mt-2">
              <label class="lb-float">De:</label>
              <!-- <money v-model="filters.fields.ValorDe" class="form-control form-control-sm" placeholder="De" v-bind="money"></money> -->
              <input 
                    type="tel" 
                    @blur="triggerFilter" 
                    v-model="filters.fields.ValorDe" 
                    class="form-control form-control-sm" 
                    placeholder="De"/>
            </li>
            <li class="dropdown-item-check mb-2 mt-2">
              <label class="lb-float">Até:</label>
              <input type="tel" 
                      @blur="triggerFilter" 
                      v-model="filters.fields.ValorAte" 
                      class="form-control form-control-sm" 
                      placeholder="Até"/>
            </li>
          </ul>
        </div>
        <div class="dropdown d-grid gap-2"> 
          <button class="btn btn-sm btn-light dropdown-toggle" 
                  type="button" 
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  >
             ANO{{anoCount}}
          </button>
          <ul class="dropdown-menu">
            <li class="text-right pr-1">
              <button type="button" @click="close" class="btn-close btn-sm" aria-label="Close" data-v-fae5bece=""></button>
            </li>
            <li data-v-61dd7a3d=""><hr class="dropdown-divider" data-v-61dd7a3d=""></li>
            <li class="dropdown-item-check mt-2 mb-2">
              <label class="lb-float">De:</label>
              <input type="text" @blur="triggerFilter" @keyup="triggerFilter" v-mask="'####'"  v-model="filters.fields.AnoDe" class="form-control form-control-sm" placeholder="De"/>
            </li>
            <li class="dropdown-item-check mt-2 mb-2">
              <label class="lb-float">Até:</label>
              <input type="tel" @blur="triggerFilter" @keyup="triggerFilter" v-mask="'####'" v-model="filters.fields.AnoAte" class="form-control form-control-sm" placeholder="Até"/>
            </li>
          </ul>
        </div>
        <div class="dropdown d-grid gap-2"> 
          <button class="btn btn-sm btn-light dropdown-toggle" 
                  type="button" 
                  
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  >
            <font-awesome-icon :icon="['fas', 'exchange-alt']"  rotation="90" /> {{orderSelected}} 
          </button>
          <ul class="dropdown-menu">
            <li class="text-right pr-1">
              <button type="button" @click="close" class="btn-close btn-sm" aria-label="Close" data-v-fae5bece=""></button>
            </li>
            <li data-v-61dd7a3d=""><hr class="dropdown-divider" data-v-61dd7a3d=""></li>
            <li class="dropdown-item-check" v-for="i in filters.source.order" :key="i.id">
              <div class="form-check">
                <input class="form-check-input" @change="triggerFilter" :value="i.id" v-model="filters.fields.OrdenacaoPagina" type="radio" :id="'order-'+i.id">
                <label class="form-check-label"  :for="'order-'+i.id">
                  {{i.name}}
                </label>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
    <div class="row">      
      <div class="col-lg-12">
        <div class="lista">
            <div class="veiculos row">
              <div class="col-lg-3 col-md-4" v-for="i in items" :key="i.codigoNegociacao">
                <router-link :to="'/detalhes/'+(i.codigoNegociacao)" class="item">
                  <div class="photo">
                    <div class="photo-holder">
                      <img :src="i.imagemCapaFormatadoUrl"/>
                    </div>
                    <span v-if="i.leilao" class="leilao">
                      <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
                      Leilão
                    </span>
                  </div>
                  <div class="descricao">
                    <span class="marca">{{i.marca}}</span>
                    <span class="modelo">{{i.modelo}}</span>
                  </div>
                  <div class="atributos">
                    <div class="attr">
                      <span>{{i.anoFabricacao}}/{{i.anoModelo}}</span>
                    </div>
                    <div class="attr">
                      <span>{{i.combustivel}}</span>
                    </div>
                  </div>
                  <div class="atributos">
                    <div class="attr">
                      <span>{{i.kmFormatado}}km</span>
                    </div>
                    <div class="attr">
                      <span>{{i.placaFormatado}}</span>
                    </div>
                  </div>
                  <div class="preco">
                    <div>
                      <span class="valor dashed">{{i.valorFipeFormatado}}</span><small> (fipe)</small>
                    </div>
                    <div>
                      <span :class="'valor '+(i.valorArremate > 0 && 'dashed')">{{i.valorPretendidoFormatado}}</span>
                    </div>
                    <div class="fecha-agora" v-if="i.valorArremate > 0">
                      <span class="valor ">{{i.valorArremateFormatado}}</span><small> (fecha agora)</small>
                    </div>
                  </div>
                  <hr/>
                  <div class="info">
                    <span><font-awesome-icon :icon="['fas', 'map-marker-alt']"  /> {{i.cidade}}/{{i.siglaEstado}}</span>
                    <span><font-awesome-icon :icon="['far', 'calendar-alt']"  /> {{i.relativeTime}}</span>
                  </div>
                </router-link>
              </div>
              <div class="col-12 loading" >
                <font-awesome-icon :icon="['fas', 'sync-alt']" spin v-if="loading"/>
              </div>
            </div>
          </div>
      </div>
      
    </div>
  </div>
  
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt, faExchangeAlt,faFilter,faSearch,faSyncAlt, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { Dropdown } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import api from '@/services/api';
import {relativeTime,parseParams,isMobile} from '@/services/util';
import {companyPath} from '@/services/auth'

library.add(faMapMarkerAlt, faCalendarAlt, faExchangeAlt, faFilter, faSearch, faSyncAlt, faExclamationCircle);

export default {
  data() {
    return {
      filterOpen: true,
      items: [],
      total: 0,
      loading: true,
      pagination: {
        NumeroPagina: 1,
        TamanhoPagina: 8,
        DeslocamentoPagina: 1
      },
      hasMore: false,
      filters: {
        source: {
          marcas: [],
          cidades: [],
          order: [{id: 'desc', name: 'Mais Recente'},
                  {id: 'asc', name: 'Mais Antigo'}]
        },
        fields: {
          CodigoMarca: [],
          CodigoCidade: [],
          OrdenacaoPagina: 'desc',
          ValorDe: '',
          ValorAte: '',
          AnoDe: '',
          AnoAte: '',

        }
        
      }
    }
  },
  methods: {
    toggleFilter(){
      this.filterOpen = !this.filterOpen;
    },
    close(event){
      

      let dropdown = event.target.closest('.dropdown')
                                 .querySelector('.dropdown-toggle');
        
      dropdown = Dropdown.getInstance(dropdown);
      
      dropdown.hide();

    },
    async get() {
      let isFirstPage = this.pagination.NumeroPagina === 1;

      if(isFirstPage) {
        this.hasMore = true;
      }

      // this.filters.fields.ValorDe = this.filters.fields.ValorDe === 0 ? '' : this.filters.fields.ValorDe;
      // this.filters.fields.ValorAte = this.filters.fields.ValorAte === 0 ? '' : this.filters.fields.ValorAte;
      try{
        let response = await api.get('/v1/negociacoes', {
          params: {
            ...this.pagination,
            ...this.filters.fields
          },
          paramsSerializer: params => {
            return parseParams(params) 
          }
        });

        let { data, totalRegistros} = response.data;



        for(let i in data) {
          data[i].relativeTime = relativeTime(data[i].dataAtivacao);
          // data[i].imagemCapaFormatadoUrl = `${location.protocol}//${companyPath}.repasys.com.br${data[i].imagemCapaUrl}`
        }

        this.items = isFirstPage ? data : [ ...this.items,...data];
        this.total = isFirstPage ? totalRegistros : this.total;

        this.hasMore = data.length === this.pagination.TamanhoPagina;
      }
      catch{}
      finally{
        this.loading = false;
      }
      
    },
    getMore() {
      let that = this;
      let footerHeight = document.querySelector('.footer-layout').scrollHeight + 
                         document.querySelector('.copyright').scrollHeight;
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + footerHeight >= document.documentElement.offsetHeight;
        
        if (bottomOfWindow && that.hasMore && !that.loading) {
          that.loading = true;
          that.pagination.NumeroPagina++;
          await that.get();
        }
      }
    },
    async getFilters(){

      this.loading = true;
      
      let response = await api.get('/v1/empresas/filtros');

      let {marcas,cidades} = response.data;

      this.filters.source.marcas = marcas;
      this.filters.source.cidades = cidades;
      
      

    },
    async triggerFilter($event) {
      if(($event.type === 'keyup' && $event.key === 'Enter') ||
          $event.type !== 'keyup') {
        this.loading = true;
        this.pagination.NumeroPagina = 1;
        await this.get();
        this.loading = false;
      }
      

    }
  },
  computed: {
    labelFiltro() {
      return this.filterOpen ? 'Fechar Filtro' : 'Mostrar Filtro';
    },
    anoCount() {
      let count = !this.filters.fields.AnoDe ? 0 : 1;
      count += !this.filters.fields.AnoAte ? 0 : 1;
      
      let text = count > 0 ? `(${count})` : '';
      
      return text;
    },
    valorCount() {
      let count = this.filters.fields.ValorDe >0 ? 1 : 0;
      count += this.filters.fields.ValorAte >0 ? 1 : 0;
      
      let text = count > 0 ? `(${count})` : '';
      
      return text;
    },
    orderSelected() {
      let order = this.filters.source.order.filter(f=>f.id === this.filters.fields.OrdenacaoPagina)[0];
      return order.name;
    }
  },
  beforeMount(){
    this.filterOpen = !isMobile();
  },
  async mounted(){
    
    await this.getFilters();
    await this.get();
    await this.getMore();
  }
}
</script>

<style scoped>
.home{
    position: relative
  }
  
.lista{
    margin-top:1.5rem;
}
  .lista h4{
    font-weight: 600;
  }
  .lista .veiculos>div {
    margin-bottom: calc(calc(var(--bs-gutter-x) * .5) * 2);
  }
  .lista .veiculos .item{
    height: 445px;
    background: var(--white);
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    display: flex;
    width: inherit;
    flex-direction: column;
    box-shadow: rgb(158 184 209 / 41%) 0px 2px 9px 0px;
    width: 100%;
    margin: 0 auto;
  }
  .lista .veiculos .item .photo{
    height: 186px;
      position: relative;
  }
  .lista .veiculos .item .photo .photo-holder{
    display: flex;
    width: 100%;
    height: 100%;
  }
    .lista .veiculos .item .photo .photo-holder img {
      width: 100%;
      height: 100%;
      object-fit: cover;   
  }
  .lista .veiculos .item .atributos{
    display:flex;
    justify-content: space-between;
    padding-top: 12px;
    padding-left:12px;
    padding-right:12px;
  }
  .lista .veiculos .item .atributos .attr{
    font-weight: 500;
    font-size: 12px;
    color: var(--dark);

  }
  .lista .veiculos .item .descricao{
    padding-top: 8px;
    padding-left:12px;
    padding-right:12px;
    height: 72px;
    overflow: auto;
  }
  .lista .veiculos .item .descricao span{
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
  .lista .veiculos .item .preco{
    padding-top: 12px;
    padding-left:12px;
    padding-right:12px;
    color: var(--dark);
    flex: 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .lista .veiculos .item .preco > div{
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .lista .veiculos .item .preco .valor{
    font-weight: 900;
    

  }
  .lista .veiculos .item .preco .dashed{
    color:rgb(139, 140, 153);
  }
  .dashed{
    text-decoration: line-through;
  }
  .lista .veiculos .item hr{
    background-color: rgb(202 204 210);
    height: 1px;
    border: 0px;
    margin: 0;
    margin-top:8px
  }
  .lista .veiculos .item .info{
    padding-left:12px;
    padding-right:12px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    color: var(--dark);
    max-height: 32px;
        height: 32px;
  }
  .lista .veiculos .item .info .span{
    text-overflow: ellipsis;
    
  }

  .lista .veiculos .item:hover{
    color: var(--primary-color)
  }
  .lista .veiculos .item {
    color: var(--dark);
  }

  .fbody>.row{
    margin-bottom: 12px;
  }

  .fecha-agora{
    color: var(--danger)
  }

  /* .options {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 0.9rem;

  }
  .options .show-filter{
    margin-left: 12px
  } */

  .backdrop {
    background: var(--bg-color);
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 1;
    overflow:auto;
  }
  .backdrop{
    display:none
  }
  .top-filter{
    background: var(--white);
    width: 100%;
    height: 100px;
    align-items: center;
    border: 1px solid var(--light);
    border-radius: 4px;
    display:flex;
    position: relative;
  }
  .top-filter .filters{
    display:flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .leilao{
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--danger);
    color: var(--white);
    font-size: 12px;
    padding: 4px 6px;
    font-weight: 600;
  }
  .top-filter .filter-title{
    font-weight: 600;
    color: #222222;
    font-size: 16px;
    line-height: 1.5;
    min-width: 210px;
    position: relative;
    overflow: hidden;
    padding: 3px 0 0 108px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    border-radius: 4px;
  }
  .top-filter .filter-title::before{
    content: '';
    position: absolute;
    top: -31px;
    left: -69px;
    width: 161px;
    height: 161px;
    background: var(--primary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    
  }
  .dropdown-item-check{
    position: relative
  }
  .top-filter .filter-title svg{
    position: absolute;
        top: 35px;
    left: 26px;
    z-index: 1;
    color: var(--white)
  }
  .text-right{
    text-align: right;
  }
  .dropdown-item-check{
    font-size: 0.75rem;
    line-height: 1.8;
    font-weight: 600;
    padding: 4px 14px 4px!important;
    height: 32px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
  }
  .dropdown-item-check input[type="text"],
  .dropdown-item-check input[type="tel"] {
    text-align:center;
  }
  .toggle-filter{
    display: none !important;;
  }
  .lb-float{
    position: absolute;
    top: -7px;
    background: var(--white);
    left: 20px;
    font-size: 11px;
    padding-left: 3px;
    padding-right: 3px;

  }
  .loading {
    text-align: center;
    min-height: 25px;
    /* margin:0; */
  }

  .filter-title svg{
    cursor:pointer;
  }
  .atualizar{
    position: absolute;
    top:-86px;
    right:0;
  }
  .relative{
    position:relative;
    
  }
@media (max-width: 992px){
  .dropdown-menu.show{
    width: 100%;
    min-height: 130px;
  }
  .lista .veiculos .item{
    max-width: 260px;
  }
  .backdrop{
    display:block
  }
  .top-filter{
    flex-direction: column;
        height: auto;
    align-items: flex-start;
  }
  .top-filter .filters{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .top-filter .filters .dropdown{
    width:100%;
    margin-top: 1rem;
  }
  .top-filter .filters .dropdown:last-child{
    margin-bottom: 1rem;
  }
  .top-filter .filter-title{
    width: 100%;
    min-height: 98px;
    border-bottom-left-radius: 0;
    justify-content: center;
    padding: 3px 0 0 92px;
  }
  .toggle-filter{
    display: grid !important;
  }
  .form-check {
    
    font-size: 1rem;
  }
}

@media (max-width: 768px){
  .lista .veiculos .item{
    max-width: 260px;
  }
  .options {
    align-self: center;
    margin-bottom: 0.9rem;
    align-self: center;
  }
  .lista .veiculos .item{
    max-width: 100%;
    height: 520px
  }
  .lista .veiculos .item .photo {
      height: 260px;
  }
  .lista .veiculos .item .atributos .attr {
    font-size:14px;
  }
  .lista .veiculos .item .preco {
    font-size: 18px;
  }

}
</style>