export function getEnvironmentUrl() {
  return 'https://resellers-api.repasys.com.br';

  let includes = location.origin.includes('teste') || location.origin.includes('localhost');

  return includes ? 
  'https://resellers-api-hml.repasys.com.br' :
  'https://resellers-api.repasys.com.br';
}

