<template>
  <div class="box">
    <div :class="'row inside-box order-lg-1 order-1 '+ (menuMobileOpen ? 'open':'')">
      <div class="col-lg-3 logo-box">
        <router-link to="/" class="logo-nav">
          <div class="logo">
            <img :src="logo" />
          </div>

        </router-link>
        <button type="button" @click="() => menuMobileOpen = !menuMobileOpen">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi" fill="currentColor"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z">
            </path>
          </svg>
        </button>
      </div>
      <div class="col-lg-6 menu-lg order-lg-2 order-3">
        <div class="nav">
          <ul>
            <li>
              <div>
                <router-link @click="() => menuMobileOpen = !menuMobileOpen" to="/" data-bs-trigger="hover"
                  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Veículos">
                  <span>
                    <font-awesome-icon :icon="['fas', 'home']" size="2x" />
                  </span>
                  <span class="show-mobile">Veículos</span>
                </router-link>
              </div>
            </li>
            <li v-if="!isUsuarioInterno">
              <div>
                <router-link @click="() => menuMobileOpen = menuMobileOpen && menuMobileOpen && !menuMobileOpen"
                  to="/minhas-ofertas" data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-container="body"
                  data-bs-placement="bottom" title="Minhas ofertas">
                  <span>
                    <font-awesome-icon :icon="['fas', 'hand-holding-usd']" size="2x" />
                  </span>
                  <span class="show-mobile">Minhas ofertas</span>
                </router-link>
              </div>
            </li>
            <li v-if="!isUsuarioInterno">
              <div>
                <router-link @click="() => menuMobileOpen = menuMobileOpen && !menuMobileOpen" to="/preferencias"
                  data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="bottom"
                  title="Preferências">
                  <span>
                    <font-awesome-icon :icon="['fas', 'cog']" size="2x" />
                  </span>
                  <span class="show-mobile">Preferências</span>

                </router-link>
              </div>
            </li>
            <li v-if="!isUsuarioInterno">
              <div>
                <router-link @click="() => menuMobileOpen = menuMobileOpen && !menuMobileOpen" to="/notificacoes"
                  data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="bottom"
                  title="Notificações">
                  <span>
                    <font-awesome-icon :icon="['far', 'bell']" size="2x" />
                  </span>
                  <span class="show-mobile">Notificações</span>

                </router-link>
              </div>
            </li>
          </ul>

        </div>
      </div>
      <div class="col-lg-3 order-lg-3 order-2">
        <div class="user-nav">
          <div class="dropdown">
            <a href="javascript:;" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"
              aria-expanded="false">
              <div class="photo">
                <img :src="userPhoto" />
              </div>
              <div class="name">
                <span>{{userName}}</span>
              </div>
            </a>
            <ul class="dropdown-menu">

              <li>
                <a class="dropdown-item" @click="sair" href="javascript:;">
                  <font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Sair do sistema
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'

import { faHome,
         faCog,
         faKey,
         faSignOutAlt,
         faHandHoldingUsd} from '@fortawesome/free-solid-svg-icons'

import { faBell } from '@fortawesome/free-regular-svg-icons'

library.add(faHome,faHandHoldingUsd,faCog,faBell,faKey, faSignOutAlt);

export default {
  name: 'Header',
  props: {
    title: String,
    logo: String,
    userPhoto: String,
    userName: String,
  },
  data(){
    return{
      menuMobileOpen: false,
    }
  },
  mounted(){
      console.log('header mounted');
      
  },
  methods: {
    sair() {
      this.$router.push('/Login');
      setTimeout(() => {
        location.reload();
      }, 1500);
    }
  },
  computed: {
    isUsuarioInterno() {
      return this.userName === 'Usuário Interno'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .box{
    position: fixed;
    top:0;
    left:0;
    right:0;
    background: var(--white);
    z-index: 5;
        box-shadow: 0 0px 8px rgb(0 0 0 / 60%);
  }
  .inside-box{
        box-sizing: border-box;
        display: flex;
        position: relative;
        height: 56px;
  }
  .logo-nav{
    margin-left: 16px;
    height: 100%;
    display: flex;
    flex-direction: row;

  }
  .logo-nav img{
    height: 46px;
  }
  .logo-nav .logo{
    flex: 1;
    max-width: 60px;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .logo-nav .title{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-left: 16px;
    color: var(--primary-color);
  }
  .logo-nav .title span{
    align-self: center;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .nav ul{
    display: flex;
    justify-content: center;
    align-content: flex-end;
    flex-grow: 1;
    margin-bottom: 0;
  }

  .nav ul li{
    flex:1;
    list-style: none;
    flex-grow: 1;
    margin-left: 8px;
    max-width: 130px;
  }
  .nav ul li:first-child{
    margin-left: 0px;
  }
  .nav ul div{
    height: calc(56px - 5px);
    display:flex;
    align-items: center;
    position: relative;
  }
  .nav a {
    font-weight: bold;
    color: #2c3e50;
    justify-content: center;
    width: 100%;
    display: flex;
    background-color: transparent;
    touch-action: manipulation;
    height: 100%;
    border-bottom: 3px solid transparent;
    align-items: center;
    margin-top: 5px;
        -webkit-tap-highlight-color: transparent;
    
  }
  .nav a.router-link-exact-active {
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
  }

  .nav a span{
    justify-content: center;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    cursor:pointer;
    border-radius: 8px;
  }
  .nav a span:hover {
    background: var(--light);
  }
  .nav a.router-link-exact-active span:hover{
    background: var(--white);
  }
.user-nav{
  height: 100%;
  text-align:center;
}
.user-nav .dropdown{
  display: inline-block;
  height: 100%;
  align-items: center;
  justify-content:center;
  padding:0;
}
  .user-nav .dropdown>a{
    display: flex;
    flex-direction:row;
    height: 100%;
    align-items: center;
    justify-content:center;
    padding:0;
  }
  .user-nav a .photo{
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  .user-nav a .photo img{
    width: 100%;
    border-radius: 14px;
  }
  .user-nav .name{
    text-align: left;
    margin-left: 8px;
  }
  
  .logo-box button{
    display:none;
      transition: box-shadow .15s ease-in-out;

  }
  .show-mobile{
      display: none !important;
    }
  
  @media (max-width: 992px){
    .inside-box{
      overflow: hidden;
    }
    .menu-lg{
      padding-top: 18px;
      padding-bottom: 18px;
    }
    .logo-box button{
      display: block;
      color: rgba(255,255,255,.55);
      border-color: rgba(255,255,255,.1);
      padding: .25rem .75rem;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: .25rem;
      transition: box-shadow .15s ease-in-out;
    }
    .logo-box button svg{
        color: var(--primary-color);
    }
    .logo-box{
      display: flex;
      justify-content: space-between;
    }
    .inside-box.open{
      height:auto;
    }
    .nav ul{
      padding:0;
      flex-direction: column
    }
    .nav ul li:first-child {
        margin-left: 8px;
    }
    .nav ul li{
        margin-left: 8px;
        margin-right: 8px;
        max-width: 100%;
    }
    .nav ul div{
      justify-content: flex-start;
    }
    /* .menu-lg{
      margin-left: 16px;
      margin-right: 16px;
    } */
    .user-nav{
      text-align: left;
      padding-left: 18px;
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: solid 1px #e6e6e6;
    }
    .nav a span:first-child{
      width: 60px
    }
    .nav a span:nth-child(2){
      justify-content: flex-start;
      margin-left: 5px
    }
    .nav a:hover,
    .nav a.router-link-active.router-link-exact-active:hover{
      background: var(--light);
      border-radius: 8px;
    }
    .nav a.router-link-active.router-link-exact-active:hover{
      border-radius: 0;
      background: var(--white);
    }
    .show-mobile{
      display: flex!important;
    }
  }
  
</style>
