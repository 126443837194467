<template>
  <footer>
    <div class="footer-layout">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col col-auto">
            <router-link class="tt-logo" to="/">
              <img class="tt-logo-dark svg-img" :src="logoPositiva" alt="">
            </router-link>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-3 companies" v-for="(i,index) in companies" :key="i.codigoEmpresa">
            <ul>
              <li v-if="i.cidade">
                <div class="icon"><font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg" /></div> {{i.cidade}}/{{i.siglaEstado}}
              </li>
              <li v-if="i.logradouro">
                <div class="icon">
                  <font-awesome-icon :icon="['fas', 'road']" size="lg" />
                </div> {{i.logradouro}}, {{i.numero}}
              </li>
              <li v-if="i.telefoneComercialFormatado">
                <div class="icon"><font-awesome-icon :icon="['fas', 'mobile-alt']" size="lg" /></div> {{ i.telefoneComercialFormatado }}
              </li>
              <!-- <li v-if="i.email">
                <div class="icon"><font-awesome-icon :icon="['far', 'envelope']" size="lg" /></div> {{i.email}}
              </li> -->
            </ul>
            <hr style="display: none" v-if="index != companies.length -1"/>
          </div>          
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <span>Desenvolvido por <a href="https://repasys.com.br" target="_blank">Repasys</a> © 2015. todos os diretos reservados</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {companyPath} from '@/services/auth';
import { faMapMarkerAlt,
         faMobileAlt,
         faRoad} from '@fortawesome/free-solid-svg-icons'

import { faEnvelope,faClock } from '@fortawesome/free-regular-svg-icons'

library.add(faMapMarkerAlt,faMobileAlt,faEnvelope,faClock,faRoad);

export default {
  name: 'Footer',
  props: {
    company: Object,
    companies: Object 
  },

  computed: {
    logoPositiva() {
      return `${location.protocol}//${companyPath}.repasys.com.br/Content/img/logo1_positiva.png`;
    } 
  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.copyright{
    background-color: var(--white);
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    -webkit-box-shadow: -2px -8px 41px -14px rgb(202 210 222 / 61%);
    -moz-box-shadow: -2px -8px 41px -14px rgba(202,210,222,0.61);
    box-shadow: -2px -8px 41px -14px rgb(202 210 222 / 61%);
    padding: 20px 15px 15px;
    font-size: 12px;
    color: var(--dark)
  }
  .footer-layout{
    background-color: var(--secondary-color);
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .tt-logo{
    text-align: center;
    display: inline-block;
    width: 194px;
    padding-bottom: 6px;
    line-height: 0;
  }
  .tt-logo img {
    width: auto;
    height: 50px;
    max-width: inherit;
  }
  footer {
    color: var(--white)
  }
  ul{
    list-style: none;
    padding: 0 0 0 0px;
    margin: 0;
  }
  li{
    padding: 0 0 4px 27px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    font-size: 12px;
    line-height: 1.4;
    color: var(--white)
  }
  li svg{
    color: var(--primary-lighter);
  }

  li .icon{
    width: 25px;
    display:inline-block;
    text-align:center;
  }

  li p{
    margin-left: 1.8rem;
  }
  @media (max-width: 992px){
    li{
      text-align: center;
    }
  }
    @media (max-width: 576px){
      .companies hr{
        background: var(--primary-lighter);
        display: block!important;
      }
      .companies li{
        padding:0
      }

    }
</style>
