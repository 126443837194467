import { parseISO, differenceInMinutes} from 'date-fns';
import api from '@/services/api';

export function setItem(key, obj, expiresIn = 1440) {

    let item = {
        store: obj,
        expiresIn: expiresIn,
        created: new Date().toISOString()
    }
    let str = JSON.stringify(item);

    localStorage.setItem(key,str);
}
export function removeItem(key) {
    localStorage.removeItem(key);
}
export function getItem(key){
    let item = localStorage.getItem(key);

    if(item) {
        item = JSON.parse(item);
        if(differenceInMinutes(parseISO(item.created),new Date()) > item.expiresIn){
            removeItem(key);
        }
        else {
            return item.store;
        }
    }

    return null;
}
export async function getItemOrFetch(key, path) {
    let savedData = getItem(key);
    if (!savedData) {
        let response = await api.get(path);
        savedData = response.data;
        setItem(key, savedData);
    }

    return savedData;
}
