import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTheMask from 'vue-the-mask';
import money from 'v-money3';


createApp(App)
    .use(router)
    .use(VueTheMask)
    .use(money)
    .component('font-awesome-icon',FontAwesomeIcon)
    .mount('#app');