import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'
import Home from '../views/Home.vue'
import {isAuthenticated} from '../services/auth';
import {getItem} from '@/services/localdb';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Veículos'
    }
  },
  {
    path: '/minhas-ofertas',
    name: 'Offers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Offers.vue'),
    meta: {
      title: 'Minhas Ofertas'
    }
  },
  {
    path: '/preferencias',
    name: 'Preferences',
    component: () => import('../views/Preferences.vue'),
    meta: {
      title: 'Preferências'
    }
  },
  {
    path: '/notificacoes',
    name: 'Notifications',
    component: () => import('../views/Notifications.vue'),
    meta: {
      title: 'Notificações'
    }
  },
  {
    path: '/detalhes/:id',
    name: 'Details',
    component: () => import('../views/Details.vue'),
    meta: {
      title: 'Detalhes do veículo'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/External.vue'),
    meta: {
      title: 'Faça o login ou solicite um cadastro',
      allowAnnonymous: true,
    }
  },
  {
    path: '/recuperar-senha/:id',
    name: 'Recovery',
    component: () => import('../views/External.vue'),
    meta: {
      title: 'Recuperação de senha',
      allowAnnonymous: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 ,behavior: 'smooth',}
  },
})

// Função para detectar dispositivo móvel
function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

function tentarAbrirApp(codigoNegociacao) {
  let company = JSON.parse(getItem('company') || false);

  if(company && company.iosDownloadLink) {
    console.log('tentando abrir app..')
    const deepLinkPorHost = location.host.substring(0,location.host.indexOf('.'))  || 'app';

    // Tentar abrir o deep link para a rota de detalhes se o referrer for externo
    const deepLink = `${deepLinkPorHost}://home/${codigoNegociacao}`;
    window.location.href = deepLink;  
  }
}
router.beforeEach((to, from, next) => {
  const isAuth = isAuthenticated();
  // Verificar se o usuário veio de um link externo, ou estava navegando no site
  const isExternalReferrer = typeof(from.name) === 'undefined';

  if (!isAuth && !to.matched.some(record => record.meta.allowAnnonymous)) {
    if(isMobileDevice() && 
        to.fullPath.includes('detalhes') && isExternalReferrer) {
      tentarAbrirApp(to.params.id);
    } 
    
    // redirecionar para tela de login
    setTimeout(() => {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      });
    }, 1500); // 1.5 segundos de timeout
    
  }
  else {
    next();
  }
});

router.afterEach((to, from) => {
      
  let company = JSON.parse(getItem('company'));

  let param = company.parametros.filter(p=>p.chave === 'empresa.nome')[0];

  let companyName = company ? `${param.valor || company.nomeFantasia} - ` : '';
  document.title = `${companyName}${to.meta.title || ''}`;

  if (to.name === 'Details' 
    //&& isMobileDevice()
  ) {
    debugger;
    // Verificar se o usuário veio de um link externo, ou estava navegando no site
    const isExternalReferrer = typeof(from.name) === 'undefined';

    isExternalReferrer && tentarAbrirApp(to.params.id); // Chama a função após a navegação ser confirmada
  } 
});

export default router;
